export enum PageTypeTrackingId {
  contentCanvas = "webpageContentCanvas",
  blogArticle = "webpageBlogArticle",
}

export interface ContentfulPageComposableOptions {
  /**
   * Setting this value to "true" will trigger the page to set SEO meta tags based on page response.
   *
   * @default undefined
   */
  setSeoMeta: boolean;
  /**
   * Providing a value will trigger cmtr to track a page info event
   *
   * @default undefined
   */
  pageTypeToTrack: PageTypeTrackingId;
}
